<template>
  <van-popup v-model="show" position="top" :close-on-click-overlay="false">
    <div class="loading-box" v-if="organizationLoading">
      <van-loading size="24px" vertical>加载中...</van-loading>
    </div>
    <!-- 加入到组织 -->
    <div class="join-organization" :style="`width:${clientWidth}px;max-height:${clientHeight * 0.8}px`" v-else-if="type === 1">
      <div style="color: #ff9900">您尚未加入到组织。</div>
      <div style="margin-top: 40px">请输入要加入的组织</div>
      <div class="input_box">
        <van-field v-model="orgName" placeholder="请输入组织名称" @input="inputName" @focus="focus" @blur="blur" />
        <div class="org-list" v-show="orgShow">
          <div class="org">
            <van-list v-model="loading" :finished="!isRequest" finished-text="" @load="onLoad">
              <div class="orgName" v-for="(item, i) in orgList" :key="i" @click="nameClick(item)">
                {{ item.name }}
              </div>
            </van-list>
          </div>
        </div>
      </div>
      <div style="color: #ff0000" v-if="(organizationId === 0 || adminId === 0 || adminId === null) && orgName !== ''">本组织尚未认证</div>
      <div class="btn">
        <van-button type="info" @click="back">返回</van-button>
        <van-button type="info" @click="type = 2" v-if="organizationId === 0 || adminId === 0 || adminId === null">申请成为管理员</van-button>
        <van-button type="info" @click="join" v-else>申请加入</van-button>
      </div>
    </div>
    <!-- 认证超级管理员 -->
    <div v-else-if="type === 2" class="join-organization" :style="`width:${clientWidth}px;max-height:${clientHeight}px`">
      <div>认证超级管理员</div>
      <div class="input_box">
        <van-field v-model="orgName" placeholder="请输入组织名称" @input="inputName" @focus="focus" @blur="blur" :disabled="true" />
        <div class="org-list" v-show="orgShow">
          <div class="org">
            <van-list v-model="loading" :finished="!isRequest" finished-text="" @load="onLoad">
              <div class="orgName" v-for="(item, i) in orgList" :key="i" @click="nameClick(item)">
                {{ item.name }}
              </div>
            </van-list>
          </div>
        </div>
      </div>
      <div class="input_box">
        <van-field v-model="paperworkNumber" placeholder="统一社会信用代码" />
      </div>

      <div style="margin-top: 20px"><i class="iconfont icon-camera"></i>拍照上传</div>
      <div class="photos">
        <div>
          <div style="font-size: 14px"></div>
          <div class="photo" @click="addImg('license')">
            <img :src="license" alt="" v-if="license" />
            <span v-else>营业执照</span>
          </div>
        </div>
        <div style="flex: 1">
          <div style="font-size: 14px"></div>
          <div class="id-photo">
            <div class="photo" @click="addImg('admin1')">
              <img :src="admin1" alt="" v-if="admin1" />
              <span v-else>身份证正面</span>
            </div>
            <div class="photo" style="margin-left: 10px" @click="addImg('admin2')">
              <img :src="admin2" alt="" v-if="admin2" />
              <span v-else>身份证反面</span>
            </div>
          </div>
        </div>
        <div>
          <div style="font-size: 14px"></div>
          <div class="photo" @click="addImg('letter')">
            <img :src="letter" alt="" v-if="letter" />
            <span v-else>授权委托书</span>
          </div>
        </div>
      </div>
      <div style="color: #4b9efb; text-align: right; margin-top: 10px" @click="download">下载授权委托书</div>
      <div class="user-info">
        <div>申请以下账号为系统管理员。</div>
        <div>账号：{{ this.userInfo.phone }} {{ this.userInfo.nickName }}</div>
      </div>
      <div class="btn">
        <van-button type="info" @click="back">返回</van-button>
        <van-button type="info" @click="submit">提交注册材料</van-button>
      </div>
    </div>
  </van-popup>
</template>

<script>
import goBackMixin from '@mixins/goBack'
import { qiniuBase } from '~api-config'
import { mapState } from 'vuex'
export default {
  name: 'join-organization',
  mixins: [goBackMixin],
  props: {},
  data() {
    return {
      hidden: false, // 是隐藏页面
      orgList: [],
      show: false,
      orgShow: false,
      orgName: '',
      offset: 0,
      size: 20,
      loading: false,
      isRequest: true, // 是否可以继续请求
      clientWidth: document.body.clientWidth,
      clientHeight: document.body.clientHeight,
      organizationId: 0,
      adminId: 0,
      organizationLoading: true,
      type: 1, // 1申请加入组织  2组织认证
      paperworkNumber: '',
      license: '', // 营业执照
      letter: '', // 授权委托书
      admin1: '', // 身份证正面
      admin2: '', // 身份证反面
    }
  },
  computed: {
    ...mapState('user', ['userInfo']),
  },
  methods: {
    focus() {
      this.orgShow = true
      this.getOrgList()
    },
    blur() {
      this.offset = 0
      setTimeout(() => {
        this.orgShow = false
      }, 200)
    },
    inputName() {
      this.organizationId = 0
      this.adminId = 0
      this.offset = 0
      this.getOrgList()
    },
    onLoad() {
      this.offset++
      this.loading = true
      this.getOrgList()
    },
    // 获取组织列表
    getOrgList() {
      this.loading = true
      this.$axios.get(`${this.$base}/management/organization/list?name=${this.orgName}&page=${this.offset}&size=${this.size}`).then((res) => {
        this.loading = false
        // 如果页码是0，那就替换原来的数组
        if (this.offset === 0) {
          this.orgList = res.data.data.content
        } else {
          // 如果页码不是0，那就在原来的数组的基础上加上数据
          this.orgList = this.orgList.concat(res.data.data.content)
        }
        // 如果返回的数据的长度小于每页的大小，那就说明没数据了，不允许继续下拉
        if (res.data.data.content.length < this.size) {
          this.isRequest = false
        } else {
          this.isRequest = true
        }
      })
    },
    // 点击列表
    nameClick(item) {
      this.orgList = []
      this.offset = 0
      this.orgName = item.name
      this.organizationId = item.id
      this.adminId = item.adminId
    },
    // 返回按钮
    back() {
      if (!this.hidden) {
        this.show = false
        if (this.$router.length <= 1) {
          console.log('直接返回到原生端')
          this.backToNative()
        } else {
          this.$router.back()
        }
      } else {
        this.type = 1
        this.show = false
      }
    },
    // 申请加入按钮
    join() {
      if (this.organizationId === 0) {
        return this.$notify('请选择要加入的组织')
      }
      this.$axios
        .post(`${this.$base}/management/user/org/apply`, {
          userId: this.userInfo.id,
          userName: this.userInfo.nickName,
          organizationId: this.organizationId,
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.$toast.success(res.data.message)
            this.back()
          } else {
            this.$notify(res.data.message)
          }
        })
        .catch((err) => {
          console.log(err)
          this.$notify('申请失败,请稍后重试')
        })
    },

    // 点击拍照
    addImg(type) {
      this.photoType = type
      const ua = navigator.userAgent
      if (ua === this.$ua.a) {
        // eslint-disable-next-line no-undef
        AND2JS.toAndrPickFile(3)
        return false
      } else {
        if (document.getElementById('file')) {
          document.getElementById('file').remove()
        }
        const ipt = document.createElement('input')
        ipt.type = 'file'
        ipt.accept = 'image/*'
        ipt.id = 'file' // 设置ID以便删除
        ipt.style.display = 'none'
        document.body.appendChild(ipt)
        document.getElementById('file').onchange = () => {
          const f = ipt.files[0]
          const qiniu = new this.$qiniu()
          qiniu.UploadFile(
            'DEFAULT',
            f,
            {
              next: (file) => {},
              complete: (res, file) => {},
              error: () => {
                this.$toast('上传失败，请重新选择')
              },
            },
            (fileopt, key, file) => {
              const url = qiniuBase + '/' + key
              this[this.photoType] = url
              this.$notify({
                message: '上传成功',
                type: 'success',
              })
            }
          )
        }
        ipt.click()
      }
    },

    // 接收原生端上传回调
    uploadFilesResultCallBack(data) {
      console.log('join的原生端回调===>')
      console.log(data)
      const list = JSON.parse(data)
      this[this.photoType] = list[0].url
    },
    // 格式化时间 年-月-日 时：分：秒
    formatDate(fmt, date) {
      let ret
      const opt = {
        'Y+': date.getFullYear().toString(), // 年
        'm+': (date.getMonth() + 1).toString(), // 月
        'd+': date.getDate().toString(), // 日
        'H+': date.getHours().toString(), // 时
        'M+': date.getMinutes().toString(), // 分
        'S+': date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      }
      for (const k in opt) {
        ret = new RegExp('(' + k + ')').exec(fmt)
        if (ret) {
          fmt = fmt.replace(ret[1], ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'))
        }
      }
      return fmt
    },
    // 下载模板
    download() {
      // const date = this.formatDate('YYYY年mm月dd日HH点MM分SS', new Date())
      // console.log('时间戳:', date)
      // 下载模板
      this.$dialog
        .confirm({
          message: '是否下载该文件?',
        })
        .then(() => {
          let url = ''
          if (window.location.href.slice(0, 5) === 'https') {
            url = 'https://oss.farbun.com/user/12178/266537640931758080管理员认证申请公函.docx'
          } else {
            url = 'http://oss.farbun.com/user/12178/266537640931758080管理员认证申请公函.docx'
          }
          const name = url.substring(url.lastIndexOf('/') + 1)
          const pathUrl = url.substring(0, url.lastIndexOf('/'))
          const ua = navigator.userAgent
          if (ua === this.$ua.a) {
            // 安卓
            // eslint-disable-next-line
            AND2JS.showFilePreview(url)
          } else if (ua === 'fb-flutter-Webview') {
            // eslint-disable-next-linetodo0927
            try {
              // 之前的方法
              // window.fbDownloadFile.postMessage(url)
              // 新更改后的方法
              window.showFilePreview.postMessage(
                JSON.stringify({
                  filePath: pathUrl,
                  fileName: name,
                })
              )
            } catch (error) {
              console.log(error, 'fbDownloadFile.postMessage')
            }
          } else if (ua === this.$ua.i) {
            // ios环境
            // eslint-disable-next-line
            window.webkit.messageHandlers.fbDownloadFile.postMessage(url)
          } else {
            window.location = url
          }
        })
        .catch((err) => {
          Promise.reject(err)
        })
    },

    // 提交审核材料
    submit() {
      if (!this.admin1 || !this.admin2) {
        this.$notify('请上传管理员身份证')
        return false
      } else if (!this.license) {
        this.$notify('请上传营业执照')
        return false
      } else if (!this.letter) {
        this.$notify('请上传授权委托书')
        return false
      } else if (!this.orgName) {
        this.$notify('请填写认证组织名称')
        return false
      } else if (!this.paperworkNumber) {
        this.$notify('统一社会信用代码')
        return false
      }
      let organizationId = null
      if (this.organizationId !== 0) {
        organizationId = this.organizationId
      }
      const params = {
        userId: this.userInfo.id,
        userName: this.userInfo.nickName,
        phone: this.userInfo.phone,
        organizationName: this.orgName,
        organizationId: organizationId,
        identityFrontImgPath: this.admin1,
        identityReverseImgPath: this.admin2,
        licenseImgPath: this.license,
        entrustImgPath: this.letter,
        paperworkNumber: this.paperworkNumber,
      }
      this.$axios
        .post(`${this.$base}/management/lawAdminManage`, params)
        .then((res) => {
          if (res.data.code === 200) {
            this.$notify({
              message: res.data.message,
              type: 'success',
            })
            this.back()
          } else {
            this.$notify(res.data.message)
          }
        })
        .catch((err) => {
          console.log(err)
          this.$notify('提交失败')
        })
    },
  },
  mounted() {
    // 关联组件内方法ocrCaseResultCallback 至 JSBridge/uploadFiles
    this.$hook(this.$bridge.uploadFiles, this.uploadFilesResultCallBack)
    // 组件销毁的时候释放桥接方法
    this.$once('hook:destroyed', () => {
      this.$unhook(this.$bridge.uploadFiles)
    })
  },
  created() {
    console.log('加载认证')
    this.$bus.$on('join-org', (params) => {
      this.show = params.show
      this.hidden = params.hidden
    })
    this.$axios.get(`${this.$base}/management/users/${this.userInfo.id}`).then((res) => {
      this.organizationLoading = false
      const { data } = res.data
      if (data && data.lawyer) {
        this.orgName = data.lawyer.lawFirmName
        this.$axios.get(`${this.$base}/management/organization/list?name=${this.orgName}&page=${0}&size=${1}`).then((res) => {
          if (res.data.code === 200 && res.data.data.content.length > 0) {
            this.orgName = res.data.data.content[0].name
            this.organizationId = res.data.data.content[0].id
            this.adminId = res.data.data.content[0].adminId
          }
        })
      }
    })
  },
  destroyed() {
    this.$bus.$off('join-org')
  },
}
</script>

<style lang="stylus" scoped>
.loading-box
  padding 20px 0px
.join-organization
  width 100px
  height auto
  max-height 80%
  background #FFF
  padding 80px 20px 80px 20px
  font-size 16px
  box-sizing border-box
  & .input_box
    margin-top 10px
    position relative
    & .van-cell
      padding-left 0px
      padding-right 0px
      border 1px solid #CCC
      border-radius 5px
      height 40px
      padding 0px
      display flex
      flex-direction row
      align-items center
    & .org-list
      position absolute
      left 0px
      top 42px
      width 100%
      max-height 200px
      overflow auto
      box-shadow 0px 1px 8px 1px rgba(64, 180, 233, 0.23)
      border-radius 4px
      background #fff
      z-index 20
      & .org
        width 100%
        & .orgName
          display -webkit-box
          -webkit-box-orient vertical
          -webkit-line-clamp 1
          overflow hidden
          height 30px
          line-height 30px
          padding 0 10px
          box-sizing border-box
          background #FFF
  & .btn
    margin-top 60px
    padding 20px 40px
    box-sizing border-box
    display flex
    align-items center
    justify-content space-between
  & .user-info
    margin-top 20px
    font-size 14px
  & .photos
    box-sizing border-box
    display flex
    align-items center
    justify-content space-between
    & > div
      width 25%
      & .id-photo
        display flex
        flex-direction row
        align-items center
      & .photo
        width 70px
        height 70px
        border 1px solid #cccccc
        display flex
        flex-direction row
        align-items center
        justify-content center
        position relative
        & > img
          width 100%
          height 100%
        & span
          font-size 12px
</style>
