var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "van-popup",
    {
      attrs: { position: "top", "close-on-click-overlay": false },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _vm.organizationLoading
        ? _c(
            "div",
            { staticClass: "loading-box" },
            [
              _c("van-loading", { attrs: { size: "24px", vertical: "" } }, [
                _vm._v("加载中..."),
              ]),
            ],
            1
          )
        : _vm.type === 1
        ? _c(
            "div",
            {
              staticClass: "join-organization",
              style: `width:${_vm.clientWidth}px;max-height:${
                _vm.clientHeight * 0.8
              }px`,
            },
            [
              _c("div", { staticStyle: { color: "#ff9900" } }, [
                _vm._v("您尚未加入到组织。"),
              ]),
              _c("div", { staticStyle: { "margin-top": "40px" } }, [
                _vm._v("请输入要加入的组织"),
              ]),
              _c(
                "div",
                { staticClass: "input_box" },
                [
                  _c("van-field", {
                    attrs: { placeholder: "请输入组织名称" },
                    on: {
                      input: _vm.inputName,
                      focus: _vm.focus,
                      blur: _vm.blur,
                    },
                    model: {
                      value: _vm.orgName,
                      callback: function ($$v) {
                        _vm.orgName = $$v
                      },
                      expression: "orgName",
                    },
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.orgShow,
                          expression: "orgShow",
                        },
                      ],
                      staticClass: "org-list",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "org" },
                        [
                          _c(
                            "van-list",
                            {
                              attrs: {
                                finished: !_vm.isRequest,
                                "finished-text": "",
                              },
                              on: { load: _vm.onLoad },
                              model: {
                                value: _vm.loading,
                                callback: function ($$v) {
                                  _vm.loading = $$v
                                },
                                expression: "loading",
                              },
                            },
                            _vm._l(_vm.orgList, function (item, i) {
                              return _c(
                                "div",
                                {
                                  key: i,
                                  staticClass: "orgName",
                                  on: {
                                    click: function ($event) {
                                      return _vm.nameClick(item)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.name) + " ")]
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              (_vm.organizationId === 0 ||
                _vm.adminId === 0 ||
                _vm.adminId === null) &&
              _vm.orgName !== ""
                ? _c("div", { staticStyle: { color: "#ff0000" } }, [
                    _vm._v("本组织尚未认证"),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "btn" },
                [
                  _c(
                    "van-button",
                    { attrs: { type: "info" }, on: { click: _vm.back } },
                    [_vm._v("返回")]
                  ),
                  _vm.organizationId === 0 ||
                  _vm.adminId === 0 ||
                  _vm.adminId === null
                    ? _c(
                        "van-button",
                        {
                          attrs: { type: "info" },
                          on: {
                            click: function ($event) {
                              _vm.type = 2
                            },
                          },
                        },
                        [_vm._v("申请成为管理员")]
                      )
                    : _c(
                        "van-button",
                        { attrs: { type: "info" }, on: { click: _vm.join } },
                        [_vm._v("申请加入")]
                      ),
                ],
                1
              ),
            ]
          )
        : _vm.type === 2
        ? _c(
            "div",
            {
              staticClass: "join-organization",
              style: `width:${_vm.clientWidth}px;max-height:${_vm.clientHeight}px`,
            },
            [
              _c("div", [_vm._v("认证超级管理员")]),
              _c(
                "div",
                { staticClass: "input_box" },
                [
                  _c("van-field", {
                    attrs: { placeholder: "请输入组织名称", disabled: true },
                    on: {
                      input: _vm.inputName,
                      focus: _vm.focus,
                      blur: _vm.blur,
                    },
                    model: {
                      value: _vm.orgName,
                      callback: function ($$v) {
                        _vm.orgName = $$v
                      },
                      expression: "orgName",
                    },
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.orgShow,
                          expression: "orgShow",
                        },
                      ],
                      staticClass: "org-list",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "org" },
                        [
                          _c(
                            "van-list",
                            {
                              attrs: {
                                finished: !_vm.isRequest,
                                "finished-text": "",
                              },
                              on: { load: _vm.onLoad },
                              model: {
                                value: _vm.loading,
                                callback: function ($$v) {
                                  _vm.loading = $$v
                                },
                                expression: "loading",
                              },
                            },
                            _vm._l(_vm.orgList, function (item, i) {
                              return _c(
                                "div",
                                {
                                  key: i,
                                  staticClass: "orgName",
                                  on: {
                                    click: function ($event) {
                                      return _vm.nameClick(item)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.name) + " ")]
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "input_box" },
                [
                  _c("van-field", {
                    attrs: { placeholder: "统一社会信用代码" },
                    model: {
                      value: _vm.paperworkNumber,
                      callback: function ($$v) {
                        _vm.paperworkNumber = $$v
                      },
                      expression: "paperworkNumber",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticStyle: { "margin-top": "20px" } }, [
                _c("i", { staticClass: "iconfont icon-camera" }),
                _vm._v("拍照上传"),
              ]),
              _c("div", { staticClass: "photos" }, [
                _c("div", [
                  _c("div", { staticStyle: { "font-size": "14px" } }),
                  _c(
                    "div",
                    {
                      staticClass: "photo",
                      on: {
                        click: function ($event) {
                          return _vm.addImg("license")
                        },
                      },
                    },
                    [
                      _vm.license
                        ? _c("img", { attrs: { src: _vm.license, alt: "" } })
                        : _c("span", [_vm._v("营业执照")]),
                    ]
                  ),
                ]),
                _c("div", { staticStyle: { flex: "1" } }, [
                  _c("div", { staticStyle: { "font-size": "14px" } }),
                  _c("div", { staticClass: "id-photo" }, [
                    _c(
                      "div",
                      {
                        staticClass: "photo",
                        on: {
                          click: function ($event) {
                            return _vm.addImg("admin1")
                          },
                        },
                      },
                      [
                        _vm.admin1
                          ? _c("img", { attrs: { src: _vm.admin1, alt: "" } })
                          : _c("span", [_vm._v("身份证正面")]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "photo",
                        staticStyle: { "margin-left": "10px" },
                        on: {
                          click: function ($event) {
                            return _vm.addImg("admin2")
                          },
                        },
                      },
                      [
                        _vm.admin2
                          ? _c("img", { attrs: { src: _vm.admin2, alt: "" } })
                          : _c("span", [_vm._v("身份证反面")]),
                      ]
                    ),
                  ]),
                ]),
                _c("div", [
                  _c("div", { staticStyle: { "font-size": "14px" } }),
                  _c(
                    "div",
                    {
                      staticClass: "photo",
                      on: {
                        click: function ($event) {
                          return _vm.addImg("letter")
                        },
                      },
                    },
                    [
                      _vm.letter
                        ? _c("img", { attrs: { src: _vm.letter, alt: "" } })
                        : _c("span", [_vm._v("授权委托书")]),
                    ]
                  ),
                ]),
              ]),
              _c(
                "div",
                {
                  staticStyle: {
                    color: "#4b9efb",
                    "text-align": "right",
                    "margin-top": "10px",
                  },
                  on: { click: _vm.download },
                },
                [_vm._v("下载授权委托书")]
              ),
              _c("div", { staticClass: "user-info" }, [
                _c("div", [_vm._v("申请以下账号为系统管理员。")]),
                _c("div", [
                  _vm._v(
                    "账号：" +
                      _vm._s(this.userInfo.phone) +
                      " " +
                      _vm._s(this.userInfo.nickName)
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "btn" },
                [
                  _c(
                    "van-button",
                    { attrs: { type: "info" }, on: { click: _vm.back } },
                    [_vm._v("返回")]
                  ),
                  _c(
                    "van-button",
                    { attrs: { type: "info" }, on: { click: _vm.submit } },
                    [_vm._v("提交注册材料")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }